import React from 'react';
import './App.css';
import {DataPlot} from "./components/plot/DataPlot";

function App() {
  return (
        <DataPlot/>
  );
}

export default App;
